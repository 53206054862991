import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { FaqPageQuery } from "../../../graphql/types"
import { PageProps } from "../../types/page"
import styles from "../../features/faq/style/faq.module.scss"
import { Page } from "@components/page/page"
import { DetailsPageHeader } from "@components/details-page-header/details-page-header"
import { Markdown } from "@components/markdown/markdown"
import { defaultMetadata } from "@core/constants"

export const FAQTemplate: FunctionComponent<PageProps<FaqPageQuery>> = ({
  data,
  ...props
}) => {
  const content = data?.contentfulFaq!
  const { metadata } = content || {}

  const metadataOverrides = {
    title: metadata?.title || defaultMetadata.title,
    description: metadata?.description || defaultMetadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || defaultMetadata.openGraphImage
  }

  const question = content.question || ""
  const answer = content.answer?.childMarkdownRemark?.rawMarkdownBody || ""

  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        ...metadataOverrides
      }}
      pageOptions={{
        headerVariant: "cream"
      }}
    >
      <DetailsPageHeader title={question} />
      <section className={styles.faqBody}>
        <div className={styles.content}>
          <Markdown content={answer} className={styles.body} />
        </div>
      </section>
    </Page>
  )
}

export default FAQTemplate

export const query = graphql`
  query FAQPage($slug: String) {
    contentfulFaq(slug: { eq: $slug }) {
      topic
      question
      metadata {
        ...Metadata
      }
      answer {
        childMarkdownRemark {
          timeToRead
          plainText
          rawMarkdownBody
        }
      }
    }
  }
`
